import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Authapi from "../../Services/Authapi";
import "./tablefilter.css";
import ls from "local-storage";
import { DataGrid, GridToolbar, GridPagination } from "@mui/x-data-grid";
import { Link, useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { MdAddBox } from "react-icons/md";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Menu from '@mui/material/Menu';
import { MdDownload } from "react-icons/md";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid';
// import Switch from "react-switch";
import { Grid } from '@material-ui/core';

const isAdminOnLeaveTicketPage = ls("roles") === "Super Admin";

const getBase64ImageFromUrl = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const Tableforfilter = () => {
  const [totalDurationTime, setTotalDurationTime] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [leaveTickets, setLeaveTickets] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // const [showButton, setShowButton] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClearSearch = () => {
    setSearchQuery("");
    setTotalDurationTime(originalData);
    setSuggestions([]);
  };

  useEffect(() => {
    let today = new Date();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    if (isAdminOnLeaveTicketPage) {
      Authapi.AllgetAttandance(month, year).then((data) => {
        const formattedData = data.data.map((item, index) => ({
          id: index,
          name: item.name,
          firstName: item.firstName,
          date: item.date,
          hours: item.hours,
          attendance: item.attendance,
          dayType: item.dayType,
          userId: item.userId,
        }));
        setTotalDurationTime(formattedData);
        setOriginalData(formattedData);
      });
    } else {
      Authapi.getAttandance(month, year).then((data) => {
        const formattedData = data.data.map((item, index) => ({
          id: index,
          name: item.name,
          firstName: item.firstName,
          date: item.date,
          hours: item.hours,
          attendance: item.attendance,
          dayType: item.dayType,
          userId: item.userId,
        }));
        setTotalDurationTime(formattedData);
        setOriginalData(formattedData);
      });
    }

    Authapi.getTickets1().then((data) => {
      setLeaveTickets(data.data);
    });
  }, []);

  const handleMonthChange = async (e) => {
    let select = e.target.value;
    let ds = new Date(select);
    let month = ds.getMonth() + 1;
    let year = ds.getFullYear();
    if (isAdminOnLeaveTicketPage) {
      Authapi.AllgetAttandance(month, year).then((data) =>
        setTotalDurationTime(
          data.data.map((item, index) => ({
            id: index,
            name: item.name,
            firstName: item.firstName,
            date: item.date,
            hours: item.hours,
            attendance: item.attendance,
            dayType: item.dayType,
            userId: item.userId,
          }))
        )
      );
    } else {
      Authapi.getAttandance(month, year).then((data) =>
        setTotalDurationTime(
          data.data.map((item, index) => ({
            id: index,
            name: item.name,
            firstName: item.firstName,
            date: item.date,
            hours: item.hours,
            attendance: item.attendance,
            dayType: item.dayType,
            userId: item.userId,
          }))
        )
      );
    }

    Authapi.getLeaveTickets().then((data) => {
      setLeaveTickets(data.data);
    });
  };

  const handleSearch = () => {
    const filteredData = totalDurationTime.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.firstName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setTotalDurationTime(filteredData);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.firstName);
    setSuggestions([]);
    handleSearch();
  };

  const handleChange = (e) => {
    const searchQuery = e.target.value;
    const suggestions = originalData.filter((item) => {
      return (
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.firstName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setSearchQuery(searchQuery);
    setSuggestions(suggestions);
  };

  var month = new Array();
  month[0] = "01";
  month[1] = "02";
  month[2] = "03";
  month[3] = "04";
  month[4] = "05";
  month[5] = "06";
  month[6] = "07";
  month[7] = "08";
  month[8] = "09";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  var d = new Date();
  var dateString = d.getFullYear() + "-" + month[d.getMonth()];

  const renderDayType = (params) => {
    const authUserId = ls("user").id;

    const leaveDateMatch = leaveTickets.some((ticket) => {
      const [startDate, endDate] = ticket.date_range.split(" to ");
      const start = new Date(startDate.split('-').reverse().join('-'));
      const end = new Date(endDate.split('-').reverse().join('-'));
      const currentDate = new Date(params.row.date.split('-').reverse().join('-'));

      const isMatch = currentDate >= start && currentDate <= end && ticket.user_id === authUserId;
      return isMatch;
    });

    if (params.value === "Left Early" && params.row.userId === authUserId && !leaveDateMatch) {
      const leaveDate = params.row.date;
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.value}
          <Tooltip title="Add Leave">
            <Link
              to={`/tickets/add?date=${leaveDate}`}
              style={{ textDecoration: "none", color: "#1b6e95", fontWeight: "bold", marginLeft: "5px", marginTop: "8px" }}
            >
              <MdAddBox size={24} />
            </Link>
          </Tooltip>
        </div>
      );
    }
    return params.value;
  };

  const columns = [
    { field: "name", headerName: "Employee ID", flex: 1, headerClassName: 'header-red' },
    { field: "firstName", headerName: "Name", flex: 1, headerClassName: 'header-red' },
    { field: "date", headerName: "Date", flex: 1, headerClassName: 'header-red' },
    { field: "hours", headerName: "Hours / Min", flex: 1, headerClassName: 'header-red' },
    { field: "attendance", headerName: "Attendance", flex: 1, headerClassName: 'header-red' },
    { field: "dayType", headerName: "Day-Type", flex: 1, headerClassName: 'header-red', renderCell: renderDayType },
  ];

  const pageSizeOptions = [5, 10, 25];
  if (!pageSizeOptions.includes(pageSize)) {
    pageSizeOptions.push(pageSize);
  }
  pageSizeOptions.push({ value: totalDurationTime.length, label: 'All' });


  const downloadAttendanceXLSX = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Attendance');
    const fillColor = 'FFFFFF00';
    for (let row = 1; row <= 2; row++) {
      for (let col = 1; col <= 5; col++) {
        const cell = worksheet.getCell(row, col);
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: fillColor }
        };
      }
    }

    const base64Image = await getBase64ImageFromUrl('/logo.png');
    const imageId = workbook.addImage({
      base64: base64Image.split(',')[1],
      extension: 'png',
    });
    const columnWidth = worksheet.getColumn(1).width || 10;
    const imageWidth = 50;
    const startCol = (columnWidth - imageWidth / 7.5) / 6;
    worksheet.addImage(imageId, {
      tl: { col: startCol, row: 0 },
      ext: { width: imageWidth, height: 40 }
    });

    worksheet.getCell('B1').value = `Cherrypik Software Attendance Report`;
    worksheet.getCell('B2').value = 'Report';
    worksheet.getCell('B3').font = { bold: true, size: 14 };

    const headerRow = worksheet.addRow(columns.filter(col => col.field !== 'firstName').map(col => col.headerName));

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF00FF00' },
      };
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    if (totalDurationTime.length === 0) {
      worksheet.getCell('A3').value = 'No Data Available';
      worksheet.mergeCells('A3:I3');
      worksheet.getCell('A3').alignment = { horizontal: 'center' };
    } else {
      totalDurationTime.forEach(row => {
        worksheet.addRow(columns.filter(col => col.field !== 'firstName').map(col => row[col.field]));
      });
    }

    worksheet.columns.forEach(column => {
      column.width = 20;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'user_attendance.xlsx');
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handlePrint = () => {
  //   window.print();
  // };

  const CustomToolbar = () => (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {/* </div>
      <div> */}
        {/* <IconButton onClick={handleMenuClick} style={{ fontSize: '16px', color: '#1976d2' }}>
          <MdDownload size={20} style={{ marginRight: '5px' }} />
          <span>Export</span>
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={downloadAttendanceXLSX}>Download as CSV</MenuItem>
          <MenuItem onClick={handlePrint}>Print</MenuItem>
        </Menu> */}
      </div>
    </GridToolbarContainer>
  );

  return (
    <>

      <div>
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
          }}
        >

          <TextField
            id="datetime-local"
            label="Select Month/Year"
            views={["year", "month"]}
            variant="outlined"
            defaultValue={dateString}
            type="Month"
            onChange={handleMonthChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: "2020-01", max: dateString }}
          />

          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleChange}
            style={{
              position: "absolute",
              right: "46px",
              marginTop: "21px"
            }}
            variant="outlined"
            InputLabelProps={{
              shrink: searchQuery !== "",
              style: {
                color: '#1b6e95',
                transform: searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 9px) scale(1)',
                transition: 'transform 0.2s ease-in-out'
              }
            }}
            InputProps={{
              style: {
                backgroundColor: 'lightgray',
                borderRadius: '4px',
                height: "40px"
              },
              endAdornment: (
                <InputAdornment position="end">
                  {searchQuery && (
                    <ClearIcon onClick={handleClearSearch} style={{ cursor: 'pointer' }} />
                  )}
                  <SearchIcon onClick={handleSearch} style={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
            }}
          />
        </div>

        {searchQuery && suggestions.length > 0 && (
          <Paper
            style={{
              position: 'absolute',
              right: '114px',
              marginTop: '-5px',
              zIndex: 1,
              width: "207px",
              maxHeight: '200px',
              overflowY: 'auto',
              border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
            }}>
            {[...new Set(suggestions.map(suggestion => suggestion.firstName))].map((firstName, index) => (
              <MenuItem
                key={index}
                onClick={() => handleSuggestionClick({ firstName })}
                style={{
                  padding: '10px 20px',
                  cursor: 'pointer'
                }}
              >
                {firstName}
              </MenuItem>
            ))}
          </Paper>
        )}

        <Box sx={{ height: 400, width: 1 }}>
          <DataGrid
            columns={columns}
            rows={totalDurationTime}
            pageSize={pageSize}
            rowsPerPageOptions={pageSizeOptions}
            components={{
              Toolbar: CustomToolbar,
              Pagination: GridPagination,
              NoRowsOverlay: () => (
                <div style={{ padding: "16px", textAlign: "center" }}>
                  No data found
                </div>
              ),
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
          <div style={{ display: "flex", marginTop: "-47px" }}>
            <IconButton onClick={handleMenuClick}
              style={{
                fontSize: '16px', color: '#1976d2',
              }}>
              <MdDownload size={20} style={{ marginRight: '5px' }} />
              <span>Export</span>
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={downloadAttendanceXLSX}>Download as CSV</MenuItem>
              {/* <MenuItem onClick={handlePrint}>Print</MenuItem> */}
            </Menu>
          </div>
        </Box>
      </div>
    </>
  );
};

export default Tableforfilter;
